import { Box, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import { useGetProductsExcel } from "./useGetProductsExcel";
import { Avatar, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useDocs } from "../../algolia/getObjects";
import { getPrice, getProductDiscount } from "../../utils/prices/priceCalc";
import { useEffect, useState } from "react";
import _ from "lodash";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    height: "95vh",
    paddingBottom: "87px",
    display: "flex",
    //justifyContent:'space-around',
    marginTop: 15,
    border: "1px solid #ccc",
    borderRadius: 10,
  },
  containerCheckbox: {
    borderRight: "1px solid #ccc",
  },
}));

const ProductsExcel = () => {
  const classes = useStyles();

  const [product, loading] = useGetProductsExcel();
  const priceList = useDocs({
    index: "priceList",
    ids: ["d", "g", "f7u9HDxyh7IOYTcFg77b"],
  });
  console.log('Product:', product,"\n \n","PriceList: ",priceList)
  
  const brands = useDocs({ index: "brands", ids: ["a"] });
  const specs = useDocs({ index: "specs", ids: ["a"] });

  const [selectedPricelist, setSelectedPricelist] = useState("d");

  let rows =
    Object.keys(priceList).length &&
    Object.keys(brands).length &&
    Object.keys(specs).length &&
    _.sortBy(product, [
      "data.brandRanking",
      "data.ranking",
      "data.name",
      "data.taste",
    ])?.map(({ data: product }) => {
      return {
        id: product?.objectID,
        name: product?.name,
        brandName: product.brandName,
        taste: product?.taste,
        thumbnail: product?.thumbnail,
        quantity: product?.quantity,
        price: getPrice({
          product,
          priceLists: priceList,
          priceListID: selectedPricelist,
          iva: priceList?.[selectedPricelist]?.iva_included,
        }),
        unit: product?.unit,
        code: product?.sellerCode,
        EAN: product?.EAN,
        discount:
          _.round(
            getProductDiscount({
              product,
              priceLists: priceList,
              priceListID: selectedPricelist,
              brand: brands[product?.brand],
              specs: Object.values(specs).filter((d) =>
                product?.specs?.includes(d.objectID)
              ),
              q: 1,
            }).discount,
            2
          ) *
            100 +
          "%",
      };
    });

  const columns = [
    {
      field: "thumbnail",
      headerName: "Imagen",
      renderCell: (params) => <Avatar src={params.value} />,
      disableExport: true,
    },
    { field: "code", headerName: "Codigo" },
    { field: "brandName", headerName: "Marca" },
    { field: "name", headerName: "Producto" },
    { field: "taste", headerName: "Sabor" },
    { field: "quantity", headerName: "Cantidad" },
    { field: "unit", headerName: "Unidad" },
    { field: "EAN", headerName: "EAN" },
    {
      field: "price",
      hide: true,
      headerName: `Precio ${
        priceList?.[selectedPricelist]?.iva_included ? "con iva" : "sin iva"
      }`,
    },
    { field: "discount", headerName: "Descuento" },
  ];

  useEffect(() => {
    try {
      if (product?.length) {
        setTimeout(() => {
          for (const e of document.querySelectorAll(".MuiDataGrid-main > div"))
            e.textContent === "MUI X Missing license key" &&
              (e.style.display = "none");
        }, 500);
      }
    } catch (e) {
      console.log(e);
    }
  }, [product]);

  return (
    <Container className={classes.container}>
      <Box style={{ width: "100%" }}>
        <RadioGroup
          row
          value={selectedPricelist}
          onChange={(e) => setSelectedPricelist(e.target.value)}
        >
          {["d", "g", "f7u9HDxyh7IOYTcFg77b"].map((p) => (
            <FormControlLabel
              key={p}
              value={p}
              control={<Radio />}
              label={priceList?.[p]?.name}
            />
          ))}
        </RadioGroup>
        {rows?.length && (
          <DataGridPremium
            rows={rows}
            components={{ Toolbar: GridToolbar }}
            loading={loading}
            columns={columns}
          />
        )}
      </Box>
    </Container>
  );
};

export default ProductsExcel;
