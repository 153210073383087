/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */

import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  FormControlLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Switch,
  Typography,
} from "@mui/material";
import moment from "moment";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsAltH,
  faShoppingBasket,
  faSlidersH,
} from "@fortawesome/free-solid-svg-icons";
import { deepPurple, green, red, yellow } from "@mui/material/colors";
import classNames from "classnames";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import inboxIn from "./inboxIn.svg";
import { PackListItem } from "../../../binsControl/binCard/BinItem";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import useStyles from "./styles";
import { Link } from "react-router-dom";

const formatWeight = (weight) => { 
  return weight?.received ? `${weight?.received / 1000}kg` : ''
}

export default function AdjustmentsHistory({ product, inventoryAdjustments }) {
  const classes = useStyles();
  const [filter, setFilter] = useState({});
  const [showAllMovements, setShowAllMovements] = useState(false);
  const inventoryFiltered = inventoryAdjustments
    .filter(
      (a) =>
        !filter.bin ||
        a?.adjustments?.map((adj) => adj.bin)?.includes(filter.bin),
    )
    .filter(
      (a) =>
        !filter.expDate ||
        a?.adjustments?.map((adj) => adj.expDate)?.includes(filter?.expDate),
    );

  const bins =
    _.uniq(
      inventoryFiltered
        .map((a) => a?.adjustments?.map((adj) => adj.bin))
        .flat(),
    ) || [];

  const expDates =
    _.filter(
      _.uniq(
        inventoryFiltered
          .map((a) => a?.adjustments?.map((adj) => adj?.expDate))
          .flat(),
      ),
      (v) => v,
    ) || [];

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 500,
        bgcolor: "background.paper",
        margin: "auto",
      }}
    >
      <h4 style={{ textDecoration: "underline" }}>Historial de cambios</h4>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {bins?.map?.((bin) => (
            <Button
              sx={{ marginX: 1 }}
              key={bin}
              variant={filter.bin === bin ? "contained" : "outlined"}
              onClick={() =>
                setFilter((f) => ({
                  ...f,
                  bin: f.bin === bin ? "" : bin,
                }))
              }
            >
              {bin}
            </Button>
          ))}
        </Box>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {expDates
            ?.sort((a, b) => moment(b).unix() - moment(a).unix())
            ?.map?.((expDate) => (
              <Button
                sx={{ marginX: 1 }}
                key={expDate}
                variant={filter.expDate === expDate ? "contained" : "outlined"}
                onClick={() =>
                  setFilter((f) => ({
                    ...f,
                    expDate: f?.expDate === expDate ? "" : expDate,
                  }))
                }
              >
                {moment(expDate).format("DD/MM/YYYY")}
              </Button>
            ))}
        </Box>
        <FormControlLabel
          control={
            <Switch
              checked={showAllMovements}
              onClick={() => setShowAllMovements((prev) => !prev)}
            />
          }
          label="Expandir Ordenes"
        />
      </Box>

      <List dense className={classes.list}>
        {_.cloneDeep(inventoryFiltered)
          .reduce((p, a) => {
            if (
              !showAllMovements &&
              p[p.length - 1]?.type === "order" &&
              a.type === "order"
            ) {
              a.adjustments.forEach((el) => {
                const element = p[p.length - 1].adjustments.find(
                  (b) => b.bin === el.bin && b.expDate === el.expDate,
                );
                if (element) {
                  element.q += el.q;
                  element.user = a.user;
                } else {
                  p[p.length - 1].adjustments.push(el);
                }
              });
              return [...p];
            }
            return [...p, a];
          }, [])
          .map((a) => ({ ...a, users: _.uniq(a.adjustments.map((b) => b?.user || a?.user)) }))
          .map((a, i) => (
            <Box key={i} sx={{ borderBottom: "solid thin rgba(0, 0, 0, .2)" }}>
              <ListItem dense>
                <ListItemAvatar>
                  <Avatar
                    sx={{
                      bgcolor:
                        a.type === "receive"
                          ? green[500]
                          : a.type === "order"
                          ? red[500]
                          : a.type === "bintransfer"
                          ? yellow[800]
                          : deepPurple[500],
                    }}
                  >
                    {a.type === "receive" ? (
                      <img src={inboxIn} alt="R" style={{ margin: 4 }} />
                    ) : (
                      <FontAwesomeIcon
                        icon={
                          a.type === "order"
                            ? faShoppingBasket
                            : a.type === "bintransfer"
                            ? faArrowsAltH
                            : faSlidersH
                        }
                      />
                    )}
                  </Avatar>
                </ListItemAvatar>

                <Box>
                  <ListItemText
                    primary={a?.adjustments?.map((adj, index) => (
                      <div key={index}>
                        <Quantity
                          tq={adj.tq}
                          q={adj.q}
                          {...{ classes, product }}
                        />{" "}
                        {adj.bin}{" "}
                        {a?.type === 'receive' && formatWeight(adj?.weight)} {" "}
                        {adj.expDate
                          ? moment(adj.expDate).format("DD/MM/YYYY")
                          : ""}
                        <br />
                      </div>
                    ))}
                  />
                  {["adjustment",'receive'].includes(a.type) && a?.controlReason && (
                    <Paper elevation={0}>
                      <span>
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <span
                            style={{
                              padding: 3,
                              borderRadius: 5,
                              background: "#536DFE",
                              marginRight: 5,
                            }}
                          >
                            <SpeakerNotesIcon
                              sx={{ color: "#fff", fontSize: 15 }}
                            />
                          </span>
                          <Typography
                            sx={{ display: "inline", fontWeight: "bold" }}
                          >
                            {a?.controlReason.reason}
                          </Typography>
                        </span>
                        <Typography>{a?.controlReason.comment}</Typography>
                      </span>
                    </Paper>
                  )}
                </Box>
                {showAllMovements && a?.order ? (
                  <Link
                    style={{ width: "100%" }}
                    target="_blank"
                    to={`/app/orders/${a?.order}`}
                  >
                    <ListItemText
                      className={classes.dateUserItem}
                      // eslint-disable-next-line no-unsafe-optional-chaining
                      primary={moment(a?.timestamp * 1000).format(
                        "DD/MM/YYYY HH:mm",
                      )}
                      secondary={
                        <>
                          {a?.order && (
                            <OpenInNewIcon style={{ fontSize: 12 }} />
                          )}
                          {a?.name || ""} | {a?.user}
                        </>
                      }
                    />
                  </Link>
                ) : (
                  <ListItemText
                    className={classes.dateUserItem}
                    // eslint-disable-next-line no-unsafe-optional-chaining
                    primary={moment(a?.timestamp * 1000).format(
                      "DD/MM/YYYY HH:mm",
                    )}
                    secondary={<>{!_.isEmpty(a?.users) ? a?.users.join(','): a?.user}</>}
                  />
                )}
              </ListItem>
            </Box>
          ))}
      </List>
    </Box>
  );
}

function Quantity({ tq, q, classes, product }) {
  return (
    <>
      <span
        className={classNames(classes.quantity, {
          [classes.quantityNegative]: q < 0,
        })}
      >
        {" "}
        {q}{" "}
      </span>
      <span className={classes.tquantity}> {tq} </span>
      <PackListItem
        classes={classes}
        q={q < 0 ? -q : q}
        pack={product.pack || 1}
      />
    </>
  );
}
