import DataGridPremium from "../DataGrid/DataGrid";
import { darken, lighten, styled } from "@mui/material/styles";
import _ from "lodash";
import { useEffect, useMemo, useReducer } from "react";
import { Grid, IconButton } from "@mui/material";
import useData from "./useData";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import Filters from "./Filters";
import { numberWithCommas } from "../Utils/NumberWithCommas";
import moment from "moment/moment";
import { useDebounce } from "../Utils/useDebounce";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

export default function Table({
  index = "transactions",
  columns = [],
  limit = 50,
  defaultSort = {},
  defaultFilters = {},
  height = 400,
  ...props
}) {
  const hiddenFields = ["timestamp", "lastAssemblyEdit"];

  const allFacets = columns.reduce(
    (p, a) => ({
      ...p,
      [_.snakeCase(a.field)]: {
        field: a.field,
        limit: a.options?.limit || 5,
        name: a?.headerName,
        multiple: a.options?.multiple,
        lookup: a?.options?.index,
        type: a?.options?.type,
        defaultFilter: a?.options?.defaultFilter,
      },
    }),
    {},
  );

  const [
    {
      sort,
      size,
      match,
      facets,
      quickFilter,
      defaultFilters: defaultFiltersState,
    },
    dispatch,
  ] = useReducer(
    (p, a) =>
      a.size
        ? { ...p, size: a.size }
        : a.sort
        ? {
            ...p,
            ...a,
            size: a.size || p.limit,
            sort: { ...a.sort, ..._.omit(defaultSort, _.keys(a.sort)) },
          }
        : a.defaultFilters
        ? {
            ...p,
            ...a,
            size: p.limit,
          }
        : _.reduce(
            a,
            (r, v, k) => {
              (typeof v === "object" && _.isEmpty(v)) || !v
                ? (r = _.omit(r, k))
                : _.set(r, k, v);
              return r;
            },
            {
              ...p,
              size: a.size || p.limit,
            },
          ),
    {
      sort: defaultSort,
      size: limit,
      match: {},
      limit,
      defaultFilters,
      facets: columns
        .filter((c) => c.options?.filter && !hiddenFields?.includes(c?.field))
        .reduce(
          (p, a) => ({
            ...p,
            [_.snakeCase(a.field)]: {
              field: a.field,
              limit: a.options?.limit || 5,
              name: a?.headerName,
              multiple: a.options?.multiple,
              lookup: a?.options?.index,
              defaultFilter: a?.options?.defaultFilter,
            },
          }),
          {},
        ),
    },
    (p) => p,
  );

  useEffect(() => {
    !_.isEqual(defaultFiltersState, defaultFilters) &&
      dispatch({ defaultFilters });
  }, [defaultFilters]);

  const quickFilterDebounce = useDebounce(quickFilter, 300);

  const defaultMemoDateFilter = useMemo(
    () =>
      columns
        .filter((d) => d.options?.defaultFilter && d.options?.type === "date")
        .map((d) => {
          const startFilter = d.options?.defaultFilter?.start;
          const endFilter = d.options?.defaultFilter?.end;

          const from = startFilter
            ? moment().add(startFilter?.[0], startFilter?.[1])?.unix()
            : moment().add(-3, "days")?.unix();

          const to = endFilter
            ? moment(from * 1000)
                .add(endFilter?.[0], endFilter?.[1])
                ?.unix()
            : moment(from * 1000)
                .add(30, "days")
                ?.unix();
          return {
            [`${d.field}_timestamp`]: { $gt: from, $lt: to },
          };
        })
        ?.reduce((p, a) => ({ ...p, ...a }), {}),
    [],
  );

  const [data, loading, , count, responseFacets] = useData({
    index,
    project: columns.map((d) =>
      d?.options?.type === "date" ? `${d.field}_timestamp` : d.field,
    ),
    lookup: columns
      .filter((d) => d?.options?.index)
      .map((d) => ({
        localField: d.field,
        from: d.options.index.name,
        as: d.field,
        fields: d.options.index.fields || [d.options.index.field],
      })),
    sort,
    match: _.mapKeys(match, (v, k) => facets[k]?.field || `${k}_timestamp`),
    facets,
    size,
    limit,
    defaultFilters: {
      ...defaultFiltersState,
      ...(quickFilterDebounce && columns.some((d) => d.options?.quickFilter)
        ? {
            $or: columns
              .filter((d) => d.options?.quickFilter)
              .map((d) => ({
                $and: quickFilterDebounce.map((v) => ({
                  [`data.${d.field}`]: {
                    $regex: v,
                    $options: "i",
                  },
                })),
              })),
          }
        : {}),
    },
    defaultDateFilters: {
      ...(columns.some(
        (d) => d.options?.defaultFilter && d.options?.type === "date",
      )
        ? {
            ...defaultMemoDateFilter,
          }
        : {}),
    },
  });
  const apiRef = useGridApiRef(null);

  // const sortMatchStringify = JSON.stringify({ sort, match });

  // useEffect(() => {
  //   if (apiRef?.current) {
  //     apiRef?.current?.scrollToIndexes?.({ rowIndex: 0, colIndex: 0 });
  //   }
  // }, [sortMatchStringify, defaultFiltersState]);

  const handleOnRowsScrollEnd = () => {
    if (!loading && count > size) {
      dispatch({ size: size + limit });
    }
  };

  const handleSortModelChange = (r) => {
    if (!loading) {
      dispatch({
        sort: r.reduce(
          (p, a) => ({ ...p, [a.field]: a.sort === "asc" ? 1 : -1 }),
          {},
        ),
      });
    }
  };

  const handleFilterModelChange = (r) => {
    dispatch({
      quickFilter: r?.quickFilterValues,
    });
  };

  const gridColumns = useMemo(() => columnsFix(columns), [columns]);
  const hasFacets = Object.entries(responseFacets)
    .map((facet) => facet[1])
    .flat().length;
  return (
    <Grid container sx={{ height, width: "100%", flexWrap: "wrap" }}>
      {hasFacets > 0 && (
        <Grid item xs={3} sx={{ maxHeight: height, overflow: "auto" }}>
          <Filters
            columns={columns}
            match={match}
            dispatch={dispatch}
            requestFacets={allFacets}
            facets={responseFacets}
          />
        </Grid>
      )}
      <Grid item xs={hasFacets > 0 ? 9 : true}>
        <StyledDataGrid
          fileName={`${index} - ${moment().format("DD/MM/YYYY")}`}
          apiRef={apiRef}
          columns={gridColumns}
          rows={data}
          getRowId={(row) => row?.objectID || row?.tranid}
          rowCount={count}
          loading={loading}
          // getRowClassName={(params) =>
          //   `super-app-theme--${params.row.amount > 0 ? "in" : "out"}`
          // }
          processRowUpdate={(updatedRow, oldRow) =>
            console.log({ updatedRow, oldRow }) && updatedRow
          }
          hideFooterPagination
          onRowsScrollEnd={handleOnRowsScrollEnd}
          sortingMode="server"
          filterMode="server"
          onSortModelChange={handleSortModelChange}
          onFilterModelChange={handleFilterModelChange}
          {...props}
        />
      </Grid>
    </Grid>
  );
}

const columnsFix = (columns) => {
  return [
    ...columns
      ?.filter((c) => !c?.options?.hide)
      ?.map((c) => {
        let cc = { ...c };
        c.field?.includes(".") &&
          (cc.valueGetter = ({ row }) => _.get(row, c.field));
        c.options?.index &&
          (cc.valueGetter = ({ value }) =>
            value.map((v) => _.get(v, c.options.index.field, "")).join("; "));
        c?.field === "show" &&
          (cc.renderCell = (value) => (
            <a href={`/app/orders/${value?.row?.objectID}`}>
              <IconButton color="primary">
                <RemoveRedEyeIcon />
              </IconButton>
            </a>
          ));
        c.options?.type === "date" &&
          (cc.renderCell = (data) => {
            const value = data?.row?.[`${data?.field}_timestamp`];
            return moment(value * 1000)?.isValid()
              ? moment(value * 1000).format("DD/MM HH:mm")
              : "-";
          });
        c.options?.type === "currency" &&
          (cc.renderCell = ({ value }) =>
            `$${numberWithCommas(value, c.options.decimals || 0)}`);
        return cc;
      }),
    // {
    //   field: "actions",
    //   type: "actions",
    //   headerName: "Actions",
    //   width: 100,
    //   cellClassName: "actions",
    //   getActions: ({ id, row }) => [
    //     <GridActionsCellItem
    //       key={0}
    //       icon={<EditIcon />}
    //       label="Edit"
    //       className="textPrimary"
    //       onClick={() => console.log(row)}
    //       color="inherit"
    //     />,
    //   ],
    // },
  ];
};

const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);

const statesColors = {
  asset: "info",
  assets_outstanding: "info",
  issued_pending: "info",
  deposited: "secondary",
  in: "success",
  custody: "warning",
  out: "error",
  bounced: "error",
};

const StyledDataGrid = styled(DataGridPremium)(({ theme }) =>
  _.mapValues(
    _.mapKeys(statesColors, (v, k) => `& .super-app-theme--${k}`),
    (v) => ({
      backgroundColor: getBackgroundColor(
        theme.palette[v].main,
        theme.palette.mode,
      ),
      "&:hover": {
        backgroundColor: getHoverBackgroundColor(
          theme.palette[v].main,
          theme.palette.mode,
        ),
      },
      "&.Mui-selected": {
        backgroundColor: getSelectedBackgroundColor(
          theme.palette[v].main,
          theme.palette.mode,
        ),
        "&:hover": {
          backgroundColor: getSelectedHoverBackgroundColor(
            theme.palette[v].main,
            theme.palette.mode,
          ),
        },
      },
    }),
  ),
);
