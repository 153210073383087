export const EDITABLE_FIELDS = [
  {
    label: "Contiene",
    field: "contains",
    isArray: true,
  },
  {
    label: "Puede contener",
    field: "canContains",
    isArray: true,
  },
  {
    label: "Ingredientes",
    field: "ingredients",
    isArray: true,
  },
  {
    label: "Gusto",
    field: "taste",
  },
  {
    label: "Marca",
    field: "brand",
  },
  {
    label: "Gramaje",
    field: "quantity+unit",
    labels: ["Cantidad", "Unidad"],
    types: ["number", "text"],
  },
  {
    label: "Pack",
    field: "pack",
    type: "number",
  },
  // {
  //   label: "precio Dietetica",
  //   field: "price",
  //   secondField: "d",
  //   type: "number",
  //   withComma: true,
  // },
  // {
  //   label: "precio General",
  //   field: "price",
  //   secondField: "g",
  //   type: "number",
  //   withComma: true,
  // },
];
