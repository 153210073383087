import { Route, Switch, Redirect } from "react-router-dom";
import ProvidersRootLayout from "./layouts/ProvidersLayout";
import ProvidersProducts from "./sections/Products";
import ProvidersHome from "./sections/Providers";
import Brands from "./sections/Brands";
import ShipmentManagment from "./sections/ShipmentManagment/ShippingManagement";
import SellerProvider from "./context/sellerContext";
import ProviderFNProvider from "./context/providerContext";
import ProductsControlSearchBox from "../productsControl/listProducts/productsControlSearchBox";
import ProductCard from "../productsControl/productCard/ProductCard";
import { Breadcrumb } from "./components/breadcrumb";
import { useSearchParams } from "./hooks/useSearchParams";
import { ProductsControlSearchBoxFilters } from "./constants/ProductsControlSearchBoxFilters";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import OrderShipmentContainer from "./sections/ShipmentManagment/[orderContainer]";
import { useUserData } from "../../context/UserContext";
import { useMongoAggregate } from "../../utils/mongoAggregate/useMongoAggregate";
import ParamPersistance from "./components/ParamPersistance";
function ProductsControlSearchBoxStyled() {
  const { getParam } = useSearchParams();
  const location = useLocation();
  const history = useHistory();
  const seller = getParam("seller");

  useEffect(() => {
    if (seller) {
      // deleteParam('providerCompany')
      return history.push(
        `/providers/productsCheck?facetFilters%5Bseller%5D=${seller}&provider=${seller}`,
      );
    }
    history.push(`/providers/productsCheck`);
  }, [location.pathname]);
  return (
    <div style={{ position: "relative", top: 56.95 }}>
      <ProductsControlSearchBox
        defaultFacetFilters={`"seller":"${seller}"`}
        filters={ProductsControlSearchBoxFilters}
        styles={{ left: 40 }}
      />
    </div>
  );
}
function ProductCardStyled({ ...rest }) {
  return (
    <div style={{ marginTop: 56.5, zIndex: 0 }}>
      <ProductCard {...rest} />
    </div>
  );
}
const routes = [
  {
    path: "/providers/productsCheck",
    exact: true,
    children: [
      {
        name: ["Proveedores", "Chequeo de productos"],
        path: "/providers/productsCheck",
        exact: true,
        dynamic: true,
        component: ProductsControlSearchBoxStyled,
      },
      {
        path: "/providers/productsCheck/:id",
        name: ["Proveedores", "Chequeo de productos", ":id"],
        exact: true,
        dynamic: true,
        component: ProductCardStyled,
      },
    ],
  },
  {
    path: "/providers/purchaseOrders",
    exact: true,
    children: [
      {
        name: ["Proveedores", "Gestión de envios"],
        path: "/providers/purchaseOrders",
        exact: true,
        dynamic: true,
        component: ShipmentManagment,
      },
      {
        path: "/providers/purchaseOrders/:id",
        name: ["Proveedores", "Gestión de envios", ":id"],
        exact: true,
        component: OrderShipmentContainer,
      },
    ],
  },
  {
    path: "/providers/products",
    component: ProvidersProducts,
    exact: true,
    name: ["Proveedores", "Productos"],
  },
  {
    path: "/providers",
    component: ProvidersHome,
    exact: true,
    name: ["Proveedores"],
  },
  {
    path: "/providers/brands",
    component: Brands,
    exact: true,
    name: ["Proveedores", "Marcas"],
  },
];

export default function Providers() {
  const { uid } = useUserData();
  const [userAuth, userAuthLoading] = useMongoAggregate({
    index: "sellers",
    aggregate: [
      {
        $match: {
          "data.users": { $in: [uid] },
        },
      },
      {
        $project: {
          "data.objectID": 1,
          "data.users": 1,
        },
      },

      {
        $addFields: {
          includesUser: {
            $cond: [
              { $eq: ["$data.users", null] },
              false,
              { $in: [uid, "$data.users"] },
            ],
          },
        },
      },
    ],
  });

  if (userAuthLoading) return;
  return (
    <ProviderFNProvider>
      <SellerProvider>
        <ParamPersistance>
          <ProvidersRootLayout>
            <Breadcrumb routes={routes} />
            {routes.map((rou) => {
              if (Object.hasOwn(rou, "children"))
                return (
                  <Switch key={rou.path}>
                    {rou.children.map((route) => (
                      <PrivateUserRoute
                        isAuthenticated={
                          userAuth.length <= 0
                            ? false
                            : userAuth[0].includesUser
                        }
                        key={route.path}
                        path={route.path}
                        exact={route.exact}
                        component={route.component}
                      />
                    ))}
                  </Switch>
                );
              return (
                <PrivateUserRoute
                  isAuthenticated={
                    userAuth.length <= 0 ? false : userAuth[0].includesUser
                  }
                  key={rou.path}
                  path={rou.path}
                  exact={rou.exact}
                  component={rou.component}
                />
              );
            })}
          </ProvidersRootLayout>
        </ParamPersistance>
      </SellerProvider>
    </ProviderFNProvider>
  );
}

function PrivateUserRoute({ component: Component, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
}
