import { Box, Chip, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  LargeCard,
  ListCard,
  RowCard,
  SimpleCard,
} from "../../components/shipment-managment/[id]/card";

import { formatCountryCurrency } from "../../../../utils/formatCountryCurrency";
import { formatUnix } from "../../utils/formatUnix";
import { MainLayout } from "../../layouts/MainLayout";
import useStyles from "./styles";
import { OrderShipmentSkeleton } from "./[orderSkeleton]";
import CommentRoundedIcon from '@mui/icons-material/CommentRounded';
import Divider from '@mui/material/Divider';
import { convertToCelcius } from "../../../../utils/convertToCelcius";
export const Comment = ({comment, reason, temperature}) => {

  const reasonAndTemperatureExist = reason && temperature
  const reasonOrTemperatureExist = reason || temperature
  const differentFromVoidString = (str) => str !== "";
  const allValuesVoid = differentFromVoidString(comment) || differentFromVoidString(temperature) || differentFromVoidString(reason)

  if(!allValuesVoid) return;
  return allValuesVoid && <Stack sx={{backgroundColor:'#f5f5f5', outline:'1px solid #d4d4d4', borderRadius:'6px', borderStartStartRadius:0}}> 
  {differentFromVoidString(comment) && <Stack alignItems='center' padding="12px" direction='row' gap='6px'>
    <CommentRoundedIcon  sx={{fontSize:18, color:"#737373" }}/>
    <Typography component="span">{comment}</Typography>
  </Stack>}
  {comment && reasonOrTemperatureExist && <Divider />}
  {reasonOrTemperatureExist && <Stack direction="row" gap="6px" flexWrap="wrap" padding="12px" >
  {reason && <Chip
      style={{
        fontWeight: 600,
        backgroundColor: "#e5e5e5",
        color: "#525252",
        borderRadius:4
      }}
      label={reason}
    />}
    {reasonAndTemperatureExist && comment && <Divider orientation="vertical" variant="middle" flexItem />}
    {temperature && <Chip
      style={{
        fontWeight: 600,
        backgroundColor: "#e5e5e5",
        color: "#525252",
        borderRadius:4
      }}
      label={temperature}
    />}
  </Stack>}
  </Stack>
}
export function OrderShipmentPresentation({
  rows,
  purchaseOrderData,
  loading,
  colVisibilityModel,
  inventoryAdjustmentData,
  columns,
}) {
  const classes = useStyles();
  const controlReason = inventoryAdjustmentData && inventoryAdjustmentData.data.controlReason
const allValuesVoid = controlReason && controlReason?.comment !== "" || controlReason?.temperature !== "" || controlReason?.reason !== "";

  if (loading) return <OrderShipmentSkeleton />;
  const purchaseOrder = purchaseOrderData?.data;
  const totalRequestedItems = purchaseOrderData
    ? Object.values(purchaseOrder.items || {})
        .map((el) => el.q && el.q)
        .reduce((acc, current) => acc + current, 0)
    : null;
  const totalReceivedItems = inventoryAdjustmentData
    ? Object.values(inventoryAdjustmentData?.data.items)
        .map((e) =>
          Object.values(e)
            .map((e) => Object.values(e)[0])
            .reduce((acc, current) => Number(acc) + Number(current), 0),
        )
        .reduce((acc, current) => Number(acc) + Number(current), 0)
    : 0;
  const getReceivedByPercentage = (total, q) => (q * 100) / total;
  const unitsStatusPercentage = getReceivedByPercentage(
    totalRequestedItems,
    totalReceivedItems,
  );
  const cardLargeHeader =
    purchaseOrder?.received.s === 0
      ? "Esperando a ser entregado"
      : unitsStatusPercentage === 100
      ? "Procesado exitosamente"
      : unitsStatusPercentage < 100 && unitsStatusPercentage > 90
      ? "Procesado con algunos problemas"
      : unitsStatusPercentage < 90 && unitsStatusPercentage > 50
      ? "Procesado con algunos problemas"
      : unitsStatusPercentage < 50 && unitsStatusPercentage > 0
      ? "Procesado con serios problemas"
      : unitsStatusPercentage <= 0
      ? "No pudo ser procesado"
      : "";
  const cardLargeSubHeader =
    purchaseOrder?.received.s === 0
      ? "Esperando a ser entregado"
      : unitsStatusPercentage === 100
      ? "Procesado con éxito"
      : unitsStatusPercentage < 100
      ? "Procesado con problemas"
      : unitsStatusPercentage <= 0
      ? "No pudo ser procesado"
      : "";

  return (
    <MainLayout>
      <Stack gap={6}>
        <Stack gap={2}>
          <Typography
            sx={{ fontSize: 20, fontWeight: 700 }}
            component="h4"
            color="text.secondary"
          >
            Resumen de envío
          </Typography>

          <Stack direction="row" gap={2} flexWrap="wrap">
            <Box flexGrow={1}>
              <RowCard
                rows={[
                  {
                    header: "Fecha de creación",
                    content: purchaseOrder?.date_timestamp
                      ? formatUnix(purchaseOrder.date_timestamp, true)
                      : "",
                  },
                  {
                    header: "Fecha de recepción",
                    content: purchaseOrder?.received?.t
                      ? formatUnix(purchaseOrder?.received?.t, true)
                      : "",
                  },
                  {
                    header: "Estado de entrega",
                    content:
                      purchaseOrder?.received?.s === 1 ||
                      purchaseOrder?.received?.t ? (
                        <Chip
                          style={{
                            fontWeight: 600,
                            backgroundColor: "#bbf7d0",
                            color: "#14532d",
                          }}
                          label={"Recibido"}
                        />
                      ) : (
                        <Chip
                          style={{
                            fontWeight: 600,
                            backgroundColor: "#fef08a",
                            color: "#713f12",
                          }}
                          label={"No Recibido"}
                        />
                      ),
                  },
                  {
                    header: allValuesVoid && "Comentarios",
                    content: controlReason && allValuesVoid &&  <Comment 
                      reason={controlReason?.reason} 
                      temperature={convertToCelcius(controlReason?.temperature)} 
                      comment={controlReason?.comment}/>
                  },
                ]}
              />
            </Box>
            {/* <Box flexGrow={1}>
              <ListCard
                list={[
                  {
                    header: "Pallets declarados / ingresados",
                    content: "Content",
                  },
                  {
                    header: "Bultos declarados / ingresados",
                    content: "Content",
                  },
                  {
                    divider: true,
                    header: "Unidades declaradas / ingresados",
                    content: "Content",
                  },
                ]}
              />
            </Box> */}
            {/* <Box flexGrow={1}>
              <ListCard
                list={[
                  { header: "Cargos", content: "Content" },
                  { header: "Incumplimientos", content: "Content" },
                  { header: "Colecta", content: "Content" },
                  {
                    divider: true,
                    header: "Total",
                    content: "Content",
                  },
                ]}
              />
            </Box> */}
            {purchaseOrder?.subTotal &&
              purchaseOrder?.iva &&
              purchaseOrder?.total && (
                <Box flexGrow={1}>
                  <ListCard
                    list={[
                      {
                        header: "Subtotal",
                        content: formatCountryCurrency(purchaseOrder?.subTotal),
                      },
                      {
                        header: "IVA",
                        content: formatCountryCurrency(purchaseOrder?.iva),
                      },
                      {
                        divider: true,
                        header: "Total",
                        content: formatCountryCurrency(purchaseOrder?.total),
                      },
                    ]}
                  />
                </Box>
              )}
          </Stack>
        </Stack>

        <Stack gap={2}>
          <Typography
            sx={{ fontSize: 20, fontWeight: 700 }}
            component="h4"
            color="text.secondary"
          >
            Estado y contenido del envío
          </Typography>
          <Stack gap={2}>
            {purchaseOrder?.received.s === 1 && (
              <LargeCard
                progressBarValue={loading ? 0 : unitsStatusPercentage}
                unitsDeclaredData={[
                  {
                    header: `${totalRequestedItems} u.`,
                    color: "#374151",
                    subHeader: "Unidades declaradas / pedidas",
                  },
                  {
                    header:
                      purchaseOrder?.received.s === 0
                        ? "---"
                        : `+${totalReceivedItems} u.`,
                    color: "#9ca3af",
                    subHeader: "Unidades recibidas",
                  },
                  {
                    header:
                      purchaseOrder?.received.s === 0
                        ? "---"
                        : `-${totalRequestedItems - totalReceivedItems} u.`,
                    color: "#9ca3af",
                    subHeader: "Unidades que no llegaron",
                  },
                ]}
                state={
                  unitsStatusPercentage < 100
                    ? "warning"
                    : unitsStatusPercentage === 100
                    ? "success"
                    : unitsStatusPercentage < 50
                    ? "danger"
                    : ""
                }
                header={cardLargeHeader}
                subheading={cardLargeSubHeader}
              />
            )}

            <Stack direction="row" gap={2} flexWrap="wrap">
              <SimpleCard
                header="Unidades declaradas / pedidas"
                content={totalRequestedItems}
              />
              <SimpleCard
                header="Unidades recibidas"
                content={
                  purchaseOrder?.received.s === 0 ? "---" : totalReceivedItems
                }
              />
              <SimpleCard
                header="Unidades que no llegaron"
                content={
                  purchaseOrder?.received.s === 0
                    ? "---"
                    : totalRequestedItems - totalReceivedItems
                }
              />
            </Stack>
          </Stack>
          <Box
            style={{ position: "relative", width: "100%", height: "500px" }}
            sx={{
              height: 700,
              overflow: "auto",
              width: "100%",
              backgroundColor: "white",
            }}
          >
            <DataGrid
              rows={rows}
              // className={classes}
              columns={columns}
              getRowHeight={() => "auto"}
              columnVisibilityModel={colVisibilityModel}
              getRowClassName={(el) => {
                if (!purchaseOrder?.received.s) return;
                if (!inventoryAdjustmentData?.data) return;
                const quantityRequested = el.row.quantity;
                const quantityReceived = inventoryAdjustmentData.data.items[
                  el.row.id
                ]
                  ? Object.values(
                      inventoryAdjustmentData?.data.items[el.row.id],
                    )
                      .map((e) => Object.values(e)[0])
                      .reduce((acc, current) => acc + current, 0)
                  : null;
                if (quantityReceived === quantityRequested) {
                  return classes.greenRow;
                }
                if (
                  quantityReceived < quantityRequested &&
                  quantityReceived &&
                  quantityReceived > 0
                ) {
                  return classes.yellowRow;
                }
                if (quantityReceived === 0 || !quantityReceived) {
                  return classes.redRow;
                }
              }}
              style={{ position: "absolute", height: "100%", width: "100%" }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 15,
                  },
                },
              }}
              pageSizeOptions={[5]}
              checkboxSelection
              disableRowSelectionOnClick
            />
          </Box>
        </Stack>
      </Stack>
    </MainLayout>
  );
}
