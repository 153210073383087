import ListItem from "@material-ui/core/ListItem";
import classnames from "classnames";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsDown as solidThumbsDownIcon } from "@fortawesome/free-solid-svg-icons/faThumbsDown";
import { faThumbsDown as regularThumbsDownIcon } from "@fortawesome/free-regular-svg-icons/faThumbsDown";
import ListItemText from "@material-ui/core/ListItemText";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import _ from "lodash";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import barcodeScan from "../../../../images/barcode-scan.png";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import { Add } from "@material-ui/icons";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import { faBox } from "@fortawesome/free-solid-svg-icons/faBox";
import "react-simple-keyboard/build/css/index.css";
import { useHistory } from "react-router-dom";
import { Box } from "@material-ui/core";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import AddBoxIcon from "@material-ui/icons/AddBox";
import BackspaceIcon from "@material-ui/icons/Backspace";
import { Button, Chip, Stack, Typography } from "@mui/material";
import { useBins } from "../../context/BinsContext";
import validReception, { minDateForReception } from "../receiveConditions";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { sellers } from "../../../../utils/sellers";
import { numberWithCommas } from "../../../../components/Utils/NumberWithCommas";

export function ProductListItem({
                                  targetBin,
                                  updateBin,
                                  setLotCode,
                                  // setLotWeight,
                                  binId,
                                  disableKeyboard,
                                  setLightboxImage,
                                  product,
                                  setEditingLot,
                                  item = {},
                                  controlItems,
                                  onClickLotList,
                                  editingLot,
                                  classes,
                                  onLotQChange,
                                  setExpDate,
                                  edit,
                                  setBad,
                                  scannedProduct = {},
                                  newItemProduct = {},
                                  task = "",
                                }) {
  const [newLot, setNewLot] = useState("");
  const history = useHistory();

  useEffect(() => {
    setNewLot("");
  }, [edit]);

  const lots = Object.entries({
    ...(newLot && edit && task !== "bintransfer" ? { [newLot]: { q: 0 } } : {}),
    ..._.mapValues(
      _.omitBy(
        _.clone((edit && task !== "bintransfer" && controlItems) || {}),
        (v, k) => item[k],
      ),
      (v) => ({
        e: v.e,
        q: 0,
      }),
    ),
    ...item,
  })
    .map((a) => [...a, controlItems[a[0]]])
    .filter(([, { q }, controlLot]) => edit || q || controlLot?.q)
    .sort(([, { e: e1 = "" }], [, { e: e2 = "" }]) => e1.localeCompare(e2));

  const weight =
    product?.grossWeight >= 1000
      ? `${product?.grossWeight / 1000}kg`
      : `${product?.grossWeight}g`;

  return (
    <>
      <ListItem
        alignItems="flex-start"
        className={classes.item}
        classes={{ selected: classes.selectedItem }}
        selected={[
          scannedProduct?.objectID,
          newItemProduct?.objectID,
        ]?.includes(product.objectID)}
      >
        <ListItemAvatar>
          <Avatar
            style={!editingLot?.lot ? { cursor: "pointer" } : {}}
            alt={`Avatar n°${product.name + 1}`}
            src={product.thumbnail}
            onClick={() => !editingLot?.lot && setLightboxImage(product.image)}
          />
        </ListItemAvatar>
        <ListItemText
          style={
            !edit
              ? { cursor: "pointer", maxWidth: "200px", margin: 0 }
              : { margin: 0 }
          }
          onClick={() =>
            !edit && history.push(`/productsCheck/${product.objectID}`)
          }
          primary={
            <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
              <Stack direction={"row"}>
                {product?.seller && (
                  <Chip
                    sx={{ marginBottom: "-5px" }}
                    label={sellers[product.seller]?.label || product.seller}
                    size="small"
                  />
                )}
                {!product.active && (
                  <Chip label="No Activo" color="error" size="small" />
                )}
              </Stack>
              <b>{product.brandName}</b>
              {product.name}
            </Box>
          }
          secondary={
            <>
              {product.quantity || ""}
              {!!product.quantity && <>{product.unit} -</>}{product.taste}
              <Box sx={{ display: 'flex' }}>
                {!!product?.grossWeight && (
                  <Stack
                    direction="row"
                    sx={{
                      background: "#ccc",
                      padding: 1,
                      borderRadius: 3,
                      minWidth: "fit-content",
                      marginRight: 1.3
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "0.875rem",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {product?.price_by === "weight" && "≈"}{weight}/u
                    </Typography>
                  </Stack>
                )}
                {product?.pack > 1 && (
                  <Stack
                    direction="row"
                    sx={{
                      background: "#ccc",
                      padding: 1,
                      borderRadius: 3,
                      minWidth: "fit-content",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "0.875rem",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faBox} /> {product?.packWeight && <>{product?.packWeight}g</>} {product?.pack}u
                    </Typography>
                  </Stack>
                )}
              </Box>
            </>
          }
        />
        {scannedProduct.objectID === product.objectID && (
          <ListItemIcon>
            <Avatar variant="square" src={barcodeScan} />
          </ListItemIcon>
        )}
        {edit && task !== "bintransfer" ? (
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="edit"
              disabled={
                !!lots.filter(
                  ([lotID, { e }, controlLot]) =>
                    lotID.includes("newLot") && (!controlLot?.q || !e),
                ).length
              }
              onClick={() =>
                !editingLot?.lot &&
                setNewLot(
                  `newLot${new Date().getTime()}${Math.floor(
                    Math.random() * 50,
                  )}`,
                )
              }
            >
              <Add />
            </IconButton>
          </ListItemSecondaryAction>
        ) : (
          <ListItemSecondaryAction>
            <ItemBins
              itemId={product.objectID}
              {...{
                binId,
                updateBin,
                targetBin,
                edit,
              }}
            />
          </ListItemSecondaryAction>
        )}
      </ListItem>
      {setEditingLot && (
        <>
          <List dense style={{ margin: 5 }} disablePadding>
            {lots.map(([lotID, { q, e, bad, lotCode, w }, controlLot]) => (
              <LotItem
                key={lotID}
                {...{
                  lots,
                  weight,
                  disableKeyboard,
                  setLotCode: lotID.includes("newLot") && setLotCode,
                  // setLotWeight,
                  task,
                  updateBin,
                  lotID,
                  onClickLotList,
                  product,
                  controlLot,
                  classes,
                  setEditingLot,
                  onLotQChange,
                  editingLot,
                  q,
                  e,
                  w,
                  lotCode,
                  scannedProduct,
                  bad,
                  setExpDate: lotID.includes("newLot") && setExpDate,
                  edit,
                  setBad,
                }}
              />
            ))}
            {/* {newLot && <NewLot product={product} classes={classes} addNewLot={addNewLot}/>} */}
          </List>
          <Divider variant="inset" component="li" />
        </>
      )}
    </>
  );
}

function ItemBins({ itemId, binId, updateBin, targetBin, edit }) {
  const { bins } = useBins();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const itemBins = bins
    .filter(
      ({ itemsArray, objectID }) =>
        binId !== objectID && itemsArray?.includes(itemId),
    )
    .sort((b, a) =>
      targetBin === a.objectID
        ? 1
        : targetBin === b.objectID
          ? -1
          : a.type === "PICKING"
            ? 1
            : b.type === "PICKING"
              ? -1
              : 0,
    );

  return !itemBins.length ? null : itemBins.length === 1 ? (
    <Box
      sx={{
        maxWidth: "100px",
        overflow: "hidden",
        border: "1px solid #536dfe8c",
        borderRadius: 6,
        padding: ".2rem .5rem",
        color: "#536dfe",
      }}
      variant={edit ? "contained" : "outlined"}
      color={targetBin === itemBins[0].objectID && edit ? "success" : "primary"}
      onClick={() =>
        edit
          ? updateBin({ targetBin: itemBins[0].objectID || "" })
          : history.push(`./${itemBins[0].objectID}`)
      }
    >
      {itemBins[0].binnumber}
    </Box>
  ) : (
    <div>
      <span
        style={{
          display: "flex",
          alignItems: "center",
          border: "1px solid #536dfe8c",
          borderRadius: 6,
          padding: ".2rem .5rem",
          color: "#536dfe",
        }}
      >
        <Box
          sx={{
            maxWidth: "71px",
            overflow: "hidden",
          }}
          id={`positioned-button-${itemId}`}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          endIcon={<FontAwesomeIcon icon={faChevronDown} />}
        >
          {itemBins[0].binnumber}
        </Box>
        <KeyboardArrowDownIcon style={{ color: "#536dfe", fontSize: 15 }} />
      </span>
      <Menu
        id={`positioned-menu-${itemId}`}
        aria-labelledby={`positioned-button-${itemId}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {itemBins.map(({ objectID, binnumber }) => (
          <MenuItem
            key={objectID}
            onClick={() => {
              handleClose();
              if (edit) {
                updateBin({ targetBin: objectID || "" });
              } else {
                history.push(`./${objectID}`);
              }
            }}
          >
            {binnumber}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export function PackListItem({ q, pack, classes = {} }) {
  return (
    pack &&
    pack > 1 &&
    q >= pack && (
      <span className={classes.lotPackQ}>
        {" "}
        {parseInt(q / pack, 10)} <FontAwesomeIcon icon={faBox} />{" "}
        {parseInt(q / pack, 10) !== q / pack && (
          <> +{q - parseInt(q / pack, 10) * pack}</>
        )}
      </span>
    )
  );
}

export function LotItem({
                          lotID,
                          updateBin,
                          lots,
                          onClickLotList,
                          product,
                          controlLot = {},
                          classes,
                          setEditingLot,
                          editingLot,
                          q,
                          e,
  w,
                          lotCode,
                          setLotCode,
                          setExpDate,
                          edit,
                          bad,
                          task = "",
                        }) {

  bad = bad || controlLot.bad;
  const q_po = lots?.find(([, , l]) => l?.q_po)?.[2]?.q_po || 0;

  const q_max =
    q_po && task === "receive"
      ? q_po -
      lots
        .filter(([id]) => id !== lotID)
        .reduce((p, [, , { q = 0 } = {}]) => p + q, 0)
      : q || 0;

  const handleAddAllQ = (q) => {
    updateBin({ [`cItems.${product.objectID}.${lotID}.q`]: q });
  };

  const scrollAuxRef = useRef(null);
  const scrollToAuxRef = useCallback(
    () => {
      // const element = scrollAuxRef.current;
      //
      // const scrollTop = element.getBoundingClientRect().top - element.offsetHeight + 250;
      //
      // element.scrollIntoView({ behavior: 'smooth', top: scrollTop,inline: "nearest" });

      scrollAuxRef.current?.scrollIntoView?.({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    },
    [scrollAuxRef],
  );

  // useEffect(() => {
  //   scannedProduct?.objectID && scrollToTop();
  // }, [scannedProduct]);

  const neto = product.price_by === "weight" && controlLot?.w_gross_kg && Number(controlLot?.w_gross_kg || 0) -
    (product.pack > 1 ? ((product.packWeight || 0) / 1000) * controlLot?.q / product.pack : 0);
  const netoUnidad = neto && neto / controlLot?.q;
  const roundedWeight = (w, product) => `${numberWithCommas((w || (product?.grossWeight*q))/1000)}kg`
  
  return (
    <Box
      ref={scrollAuxRef}
      style={{ margin: 3, "scroll-margin-top": "170px" }}
    >
      {task === "receive" && (
        <Chip
          style={{ marginLeft: "16px", marginBottom: 5 }}
          size="small"
          label={
            <>
              Fecha minima :
              {minDateForReception(product?.shelflife).format("DD/MM/YYYY")}
            </>
          }
          color={
            !e
              ? "secondary"
              : validReception(e, product?.shelflife)
                ? "success"
                : "error"
          }
        />
      )}
      <Box
        style={{
          pl: 4,
          zIndex: 30,
          display: "flex",
          justifyContent: "space-between",
        }}
        button
        onClick={() => {
          const condition =
            task === "adjustment" &&
            !editingLot?.lot &&
            (q || controlLot?.q) &&
            edit;
          if (condition) {
            onClickLotList(product.objectID, lotID, !controlLot?.checked);
          }
        }}
        className={classnames({
          [classes.checkedLot]:
          task === "adjustment" && !!controlLot?.checked && edit,
        })}
      >
        {!editingLot?.lot &&
          edit &&
          !!(q || controlLot?.q) &&
          task === "adjustment" && (
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={!!controlLot?.checked}
                tabIndex={-1}
                disableRipple
              />
              {
                //! (q || controlLot?.q) ||
                bad && (
                  <FontAwesomeIcon
                    className={classes.badIcon}
                    icon={bad ? solidThumbsDownIcon : regularThumbsDownIcon}
                  />
                )
              }
            </ListItemIcon>
          )}
        <div style={{ marginRight: 10 }}>
          {setExpDate && !controlLot?.checked && edit ? (
            <Button
              // eslint-disable-next-line no-shadow
              onClick={(e) => {
                if (!editingLot?.lot && !controlLot?.checked && edit) {
                  e.stopPropagation();
                  scrollToAuxRef();
                  setEditingLot({
                    item: product.objectID,
                    lot: lotID,
                    field: "date",
                  });
                }
              }}
              className={classnames(classes.lotQ, {
                [classes.lotQChecked]: !!controlLot?.checked && edit,
                [classes.quantitySelected]:
                editingLot?.lot === lotID && editingLot?.field === "date",
              })}
              variant={!controlLot?.checked && edit ? "contained" : "text"}
            >
              {editingLot?.lot === lotID &&
              editingLot?.field === "date" &&
              (editingLot?.tempDate || !e)
                ? Array.apply(null, Array(6)).reduce(
                  (p, a, i) =>
                    p +
                    (i && i % 2 === 0 ? "/" : "") +
                    (editingLot?.tempDate?.substring(i, i + 1) || "-"),
                  "",
                )
                : e
                  ? moment(e, "YYYY-MM-DD").format("DD/MM/YYYY")
                  : "-"}
            </Button>
          ) : (
            <ListItemText
              primary={e ? moment(e, "YYYY-MM-DD").format("DD/MM/YYYY") : "-"}
            />
          )}

          {setLotCode &&
          (task === "adjustment" || task === "receive") &&
          !controlLot?.checked &&
          edit ? (
            ""
          ) : (
            // <TextField
            //   variant="standard"
            //   defaultValue={controlLot?.lotCode || lotCode || ""}
            //   onBlur={(e) => {
            //     setLotCode(product.objectID, lotID, e.target.value);
            //     disableKeyboard(false);
            //   }}
            //   onClick={(e) => {
            //     e.stopPropagation();
            //     disableKeyboard(true);
            //   }}
            //   label="Codigo del lote"
            //   sx={{
            //     marginRight: 3,
            //     marginLeft: 1,
            //   }}
            // />
            <ListItemText>{controlLot?.lotCode || lotCode || ""}</ListItemText>
          )}
        </div>
        <Box>
          {task === "receive" && edit && product.price_by === "weight" ? (
            <Button
              style={{ marginRight: 5, ...(editingLot?.lot === lotID && editingLot?.field === "w" ? { backgroundColor: "#d32f2f" } : {}) }}
              onClick={(e) => {
                if (!editingLot?.lot && !controlLot?.checked && edit) {
                  e.stopPropagation();
                  scrollToAuxRef();
                  setEditingLot({
                    item: product.objectID,
                    lot: lotID,
                    field: "w",
                  });
                }
              }}
              variant={!controlLot?.checked && edit ? "contained" : "text"}
            ><p style={{ margin: 0 }}>
              <span style={{ fontSize: "0.8em" }}>Bruto: </span>
              {numberWithCommas(controlLot?.w_gross_kg || "0")} KG
              <br />
              <span style={{ fontSize: "0.8em" }}>
                Neto: {numberWithCommas(_.round(neto, 2))} KG
                </span>
            </p>
            </Button>
          ) : (
            (w || product?.grossWeight) && <Typography>{roundedWeight(w, product)}</Typography>
          )}
        </Box>
        {(task === "bintransfer" ||
          !!(task === "receive" && q_po && (q_max || controlLot?.q))) && (
          <Box display="flex" marginRight={2} padding=".1rem">
            <Box
              margin=".4rem"
              borderRadius={4}
              onClick={
                (controlLot?.q || 0) < q_max
                  ? () => handleAddAllQ(q_max)
                  : () => handleAddAllQ(0)
              }
            >
              {(controlLot?.q || 0) < q_max ? (
                <AddBoxIcon style={{ fontSize: 30, color: "#1976d2" }} />
              ) : (
                <BackspaceIcon style={{ fontSize: 30, color: "#ccc" }} />
              )}
            </Box>
          </Box>
        )}
        <Button
          onClick={(e) => {
            if (!editingLot?.lot && !controlLot?.checked && edit) {
              e.stopPropagation();
              scrollToAuxRef();
              setEditingLot({ item: product.objectID, lot: lotID, field: "q" });
              if (task === "bintransfer")
                updateBin({ [`cItems.${product.objectID}.${lotID}.q`]: 0 });
            }
          }}
          className={classnames(classes.lotQ, {
            [classes.lotQNoEdit]: !edit,
            [classes.lotQChecked]: !!controlLot?.checked && edit,
            [classes.quantitySelected]:
            editingLot?.lot === lotID && editingLot?.field === "q",
          })}
          variant={!controlLot?.checked && edit ? "contained" : "text"}
        >
          <p style={{ margin: 0 }}>
            {task === "bintransfer" || (task === "receive" && q_po) ? (
              <>
                <span className={classes.quantity}>{controlLot?.q || 0}</span>
                <span className={classes.onBinQuantity}>
                {" de "}
                  {q_po || q}
              </span>
                <PackListItem
                  classes={classes}
                  q={controlLot?.q || 0}
                  pack={product.pack || 1}
                />
              </>
            ) : edit ? (
              <>
                {" "}
                <span className={classes.quantity}> {controlLot?.q ?? q} </span>
                {!!(
                  controlLot?.q >= 0 &&
                  controlLot?.q !== q &&
                  task !== "receive"
                ) && <span className={classes.oldQuantity}> {q} </span>}
                {!String(controlLot?.q)?.includes(".") && (
                  <PackListItem
                    classes={classes}
                    q={controlLot?.q || q}
                    pack={product.pack || 1}
                  />
                )}
              </>
            ) : (
              <>
                <span className={classes.quantity}> {q} </span>
                <PackListItem classes={classes} q={q} pack={product.pack || 1} />

              </>
            )}
            {
              netoUnidad &&
              <span style={{ fontSize: "0.9em" }}><br />≈ {numberWithCommas(_.round(netoUnidad, 2))} KG/u</span>
            }
          </p>

        </Button>
      </Box>
    </Box>
  );
}
