import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import { Asynchronous } from "../../../../../components/CardFirestore/DataCard/components/EditForm";
import { useDocs } from "../../../../../algolia/getObjects";
import { getPrice } from "../../../../../utils/prices/priceCalc";
import { useOrder } from "../../ordenesKoco";
import _ from "lodash";
import { updateDoc } from "../../../../../firebase/database";

function AddItem() {
  const order = useOrder((s) => s.order);

  const [open, setOpen] = useState(false);
  const [price, setPrice] = useState();
  const [q, setQ] = useState();
  const [product, setProduct] = useState();
  const priceList = useDocs({
    index: "priceList",
    ids: [order.priceList],
  });

  useEffect(() => {
    product &&
      setPrice(
        getPrice({
          product,
          priceLists: priceList,
          priceListID: order.priceList,
          iva: false,
        }),
      );
  }, [product, priceList]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const exists = _.keys(order?.items)?.includes(product?.objectID);
  const agregarItem = () => {
    handleClose();
    !exists &&
      Number(q) &&
      updateDoc(
        "orders",
        order?.objectID,
        {
          [`items.${product?.objectID}`]: {
            price,
            q,
          },
        },
        ["modified_date"],
      );
  };

  return (
    <React.Fragment>
      {
        <>
          <Button onClick={handleClickOpen}>+ Agregar Item</Button>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Agregar Producto</DialogTitle>
            <DialogContent sx={{ minWidth: "300px" }}>
              {exists && (
                <DialogContentText sx={{ color: "red" }}>
                  El producto ya existe en la orden
                </DialogContentText>
              )}
              <Asynchronous
                values={product}
                index={"products"}
                customText={
                  "{{brandName}} {{name}} - {{taste}} {{quantity}}{{unit}}"
                }
                onChange={(d, hit) => {
                  setProduct(hit);
                }}
              />
              {product && (
                <>
                  <TextField
                    value={q || 0}
                    margin="dense"
                    label="Cantidad"
                    type="number"
                    fullWidth
                    variant="standard"
                    onChange={(e) =>
                      setQ(
                        _.min([
                          Number(e.target.value),
                          product?.available ?? 100,
                        ]),
                      )
                    }
                  />
                  <TextField
                    value={price}
                    margin="dense"
                    label="Precio"
                    type="number"
                    fullWidth
                    variant="standard"
                    onChange={(e) => setPrice(Number(e.target.value))}
                  />
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button disable={exists} onClick={agregarItem}>
                Agregar
              </Button>
            </DialogActions>
          </Dialog>
        </>
      }
    </React.Fragment>
  );
}

export default function AddItemComponent() {
  const order = useOrder((s) => s.order);

  return useMemo(
    () => ["no", "assembling"].includes(order.assemblyState) && <AddItem />,
    [order],
  );
}
