/* eslint-disable react/prop-types */

import React from 'react';
import {
  Avatar, Box, ListItem, ListItemAvatar, makeStyles,
} from '@material-ui/core';
import moment from 'moment';
import classnames from 'classnames';
import { LinearProgressLabel } from './LinearProgressLabel';

const useStyles = makeStyles((theme) => ({
  productListBox: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    backgroundColor: '#fff',
    marginBottom: 5,
    cursor: 'pointer',
  },
  productName: {
    fontWeight: 'bold',
    margin: 0,
    fontSize: '1rem',
    textAlign: 'left',
  },
  productTaste: {
    margin: 0,
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 2,
    textDecoration: 'underline',
    fontWeight: 'bold',
    fontSize: '.8rem',
  },
  infoBox: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  linearsBox: {
    minWidth: 150,
    width: '60%',
  },
  badgeStock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '.2rem .5rem',
    backgroundColor: theme.palette.success.main,
    color: '#fff',
    fontSize: '.6rem',
    fontWeight: 'bold',
  },
  badgeNoStock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '.2rem .5rem',
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
    fontSize: '.6rem',
    fontWeight: 'bold',
  },
  expirationdate: {
    margin: 0,
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 2,
    fontWeight: 'bold',
    fontSize: '.8rem',
  },
  expirated: {
    color: 'red',
  },

}));

export function ProductList({ product }) {
  const normalise = (value) => ((value - 1) * 100) / (product.onbin - 1);
  const classes = useStyles();
  return (

    <ListItem className={classes.productListBox} key={product?.objectID}>
      <p className={classes.productName}>{`${product?.name}(${product?.quantity}${product?.unit}) - ${product?.brandName}`}</p>
      <Box className={product?.nostock ? classes.badgeNoStock : classes.badgeStock}>{product?.nostock === true ? 'SIN STOCK' : 'EN STOCK'}</Box>
      {!product?.active && <Box className={classes.badgeNoStock}>No Activo</Box>}
      <Box className={classes.productTaste}>{product?.taste}</Box>
      {!!product.expirationdate && product.expirationdate !== '2030-01-01' && (
      <Box className={classnames({
        [classes.expirationdate]: true,
        [classes.expirated]: moment.duration(moment(product?.expirationdate).diff()).asDays() < 30,
      })}
      >
        VTO
        {moment(product?.expirationdate).format('DD/MM/YYYY')}
      </Box>
      )}
      <Box className={classes.infoBox}>
        {product.onbin
          ? (
            <Box className={classes.linearsBox}>
              <LinearProgressLabel value={normalise(product.onbin)} label="Total" info={product.onbin} />
              <LinearProgressLabel value={normalise(product.commited)} label="Reservado" info={product.commited} />
              {!!product.notavailable && <LinearProgressLabel value={normalise(product.notavailable)} label="No Disponible" info={product.notavailable} />}
              <LinearProgressLabel value={normalise(product.onbinPICKING)} label="En picking" info={product.onbinPICKING} />
            </Box>
          )
          : <p style={{ textDecoration: 'underline', color: '#FF5C93' }}>Producto sin Stock</p>}
        <ListItemAvatar>
          <Avatar src={product?.thumbnail} />
        </ListItemAvatar>
      </Box>
    </ListItem>
  );
}
